html,
body {
  height: 100%;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */

::-webkit-scrollbar {
  display: none !important;
}

/* .input-focus:focus {
} */

.form-control.input-focus:focus {
  transition: all 100ms ease-in-out;
  border: 2.5px solid #2c3f9b !important;

  box-shadow: none !important;
}

.form-control.input-focus::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.margin-top {
  margin-top: 20px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.push-right {
  margin-left: auto;
  display: flex;
  flex-direction: row;
}

.push-left {
  margin-right: auto;
  display: flex;
  flex-direction: row;
}

.all-width {
  width: 100%;
}

.all-app-remaining-height {
  max-height: calc(100vh - 81px);
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}

.table {
  margin-bottom: 0px !important;
}

.pulse {
  display: block;
  border-radius: 50%;
  cursor: pointer;
  animation: pulse 2s infinite;
}

.optionButton:hover {
  opacity: 0.8;
}

.inhibitors-spinner-flexbox {
  display: flex;
  justify-content: center;
}

.inhibitors-buttons-grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  width: 100%;
}

.inhibitors-buttons-grid button {
  width: 50%;
  margin: 10px;
}

@media (max-width: 1700px) {
  .inhibitors-buttons-grid {
    grid-template-columns: auto;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
}

.blinking {
  animation: fade 0.5s infinite alternate;
}

.leaflet-marker-icon.leaflet-div-icon.leaflet-zoom-animated.leaflet-interactive {
  background-color: transparent;
  border: none;
}

.detectorsCard.open {
  width: fit-content;
  transition: all 0.3s ease;
  height: 130px;
}

.detectorsCard {
  height: 55px;
  width: 40px;
  background-color: white;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transition: all 0.3s ease;
  border-radius: 7px;
  padding: 13px;
  cursor: pointer;
  overflow: hidden;
}

.inhibitorCard {
  margin-left: 10px;
  z-index: 510;
  width: 500px;
  height: 0px;
  transition: all 0.3s ease;
}

.inhibitorCard.open {
  height: 165px;
  transition: all 0.3s ease;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes glow {
  from {
    text-shadow: 0 0 20px red;
  }
  to {
    text-shadow: 0 0 30px red, 0 0 10px red;
  }
}

@keyframes activeDetection {
  from {
    background-color: rgba(255, 0, 0, 0.6);
  }
  to {
    background-color: rgba(255, 0, 0, 1);
  }
}

@keyframes activeDetectionOrange {
  from {
    background-color: rgba(255, 102, 0, 0.6);
  }
  to {
    background-color: rgb(255, 102, 0);
  }
}

@keyframes appear {
  from {
    margin-top: 120vh;
  }
  to {
    margin-top: 0vh;
  }
}

.falsePositivesCard {
  animation: activeDetectionOrange 1s ease-in-out infinite alternate !important;
}

.custom-br {
  display: block; /* makes it have a width */
  content: ""; /* clears default height */
  margin-top: -8px; /* change this to whatever height you want it */
}

.tab-content {
  height: 100%;
  border: 1px solid rgb(220, 220, 220);
  border-top: none;
  padding: 1rem;
}

.modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.modal-dialog {
  min-width: 40%;
  max-width: none!important;
}